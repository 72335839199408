import React from "react"
import { graphql, navigate } from "gatsby";
import { Layout } from "@components/Layout";
import { mapToSEO, SEO } from "@components/SEO";
import { Grid, Icon } from "semantic-ui-react";
import { mapToCheckoutSelectionCard } from "@mappers/Checkout/CheckoutSelectionCard";
import OrderSummary from "@components/OrderSummary/OrderSummary";
import theme from "@styles/theme";
import { Record } from 'immutable';
import { connect } from 'react-redux';
import { Dispatch } from "redux"
import { currentCheckoutSelector } from "@redux/selectors/checkoutSelector";
import { currentBasketSelector } from "@redux/selectors/basketSelector";
import { updateGiftingType, updatePersonalDetails } from "@redux/actions";
import { getCurrentCheckout } from "@redux/localStorage/checkout";
import { isMobile } from "@utils/Helpers";
import { NavHeader } from "@components/Mobile/NavHeader/NavHeader";
import MobileGenericBannerContainer from "@components/Mobile/GenericBannerContainer/GenericBannerContainer";
import MobileGiftingOptionsSelection from "@components/Mobile/GiftingSelection/GiftingOptionsSelection";
import { Checkout } from "@models/Checkout/Checkout";
import { mapToCheckoutGiftingTemplate } from "@components/Mobile/GiftingSelection/mapper";
import { Back, Info, NavHeaderSegmentStyle } from "@components/Mobile/NavHeader/NavHeader.style";
import { getCurrentBasket } from "@redux/localStorage/basket";
import { TransactionTypesSetting } from "@models/ProductModels";

const GiftingOptionsSelectionPageContainer = (props: any) => {

  const { elements } = props.data.allKontentItemPlateOwnerSelectionPage.nodes[0];
  const seo = mapToSEO(elements);
  const apiUrl = props.data.site.siteMetadata.apiUrl;
  const eCard = mapToCheckoutSelectionCard(elements.plate_owner_card.linked_items[0].elements);
  const eCardTemplate = mapToCheckoutGiftingTemplate(elements.plate_owner_card.linked_items[0].elements.gifting_template.linked_items[0].elements);
  const transactionTypeId = getCurrentBasket().basketLines.length > 0 ? getCurrentBasket().basketLines[0].transactionTypeId : TransactionTypesSetting.New.id;

  if(isMobile() === undefined){
    return null;
  }

  const goBackToOptions =(giftingType: number) => {
    props.updateGiftingType(giftingType)
  }

  return (
    <Layout version="simple" currentStep={3} location={props.location}>
      <SEO {...seo} />
      <MobileGenericBannerContainer
        padding={{
          mobile: {
            top: 20,
            bottom: 40
          },
          desktop: {
            top: 40,
            bottom: 60
          }
        }}
        backgroundColor={isMobile() ? theme.mobileTheme.bgColor : theme.brand.colors.beige} paddingBottom={60} paddingTop={40}>
        <Grid stackable reversed='mobile tablet vertically' stretched>
          <Grid.Column mobile={16} tablet={16} computer={10}>
            <MobileGiftingOptionsSelection
              heading={elements.heading.value}
              subHeading={elements.sub_heading.value}
              body={elements.body.value}
              eCard={eCard}
              basket={props.basket}
              checkout={props.checkoutState}
              updateGiftingType={props.updateGiftingType}
              updatePersonalDetails={props.updatePersonalDetails}
              apiUrl={apiUrl} 
              eCardTemplate={eCardTemplate}/> 
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={5} floated='right'>
            {isMobile() ? (transactionTypeId == TransactionTypesSetting.New.id ? <NavHeader link="/gifting-selection" info="Gifting"/> : <NavHeader link="/plate-owner-selection" info="Plate Owner"/>)
            /* props.checkoutState.giftingType == 0 ? <NavHeader link="/gifting-selection" info="Gifting"/>  : */ 
            /* <NavHeaderSegmentStyle>
              <Back onClick={() => goBackToOptions(0) }>
                <Icon name='angle left' size='large'/>
                <span>Back</span>
              </Back>
              <Info>
                Gifting
              </Info>
            </NavHeaderSegmentStyle> */ :
            <OrderSummary isExpanded={false} isLayby={false} showSurcharge={false} basket={props.basket} apiUrl={apiUrl} />}
          </Grid.Column>
        </Grid>
      </MobileGenericBannerContainer>
    </Layout>);

}

const mapStateToProps = (state: Record<any>) => {
  const checkout = currentCheckoutSelector(state).toJS();
  const basket = currentBasketSelector(state).toJS();

  return {
    checkoutState: checkout,
    basket: basket
  };
}
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateGiftingType(giftingType: number) {
    dispatch(updateGiftingType(giftingType));
  },
  updatePersonalDetails(personalDetails: Checkout) {
    dispatch(updatePersonalDetails(personalDetails))
  }
})

const GiftingOptionsSelectionPage = connect(mapStateToProps, mapDispatchToProps)(GiftingOptionsSelectionPageContainer)


export const query = graphql`
{
  site {
    siteMetadata {
      apiUrl
    }
  }
  allKontentItemPlateOwnerSelectionPage(filter: {elements: {heading: {value: {eq: "Personalise Your Ecard"}}}}) {
    nodes {
      elements {
        body {
          value
        }
        heading {
          value
        }
        seo__nofollow {
          value {
            codename
            name
          }
        }
        seo__noindex {
          value {
            codename
            name
          }
        }
        seo__page_description {
          value
        }
        seo__page_title {
          value
        }
        sub_heading {
          value
        }
        url{
          value
        }
        plate_owner_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content{
                  value
                }
                gifting_template {
                  linked_items {
                    ... on KontentItemCheckoutGiftingOptionItem {
                      elements {
                        message {
                          value
                        }
                        declaration {
                          value
                        }
                        footer1 {
                          value
                        }
                        footer2 {
                          value
                        }
                        footer3 {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
        gifting_card {
          linked_items {
            ... on KontentItemCheckoutSelectionCard {
              id
              elements {
                body {
                  value
                }
                heading {
                  value
                }
                content{
                  value
                }
                items {
                  linked_items {
                    ... on KontentItemCheckoutSelectionItem {
                      elements {
                        title {
                          value
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }       
        
      }
    }
  }
}
`

export default GiftingOptionsSelectionPage;
